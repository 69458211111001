import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import Button from '../components/Button';
import Exp from '../images/brokered-by-exp-xolor.png'


const JoinUs = () => (
    <Layout>
    <Helmet   
      htmlAttributes={{
      lang: 'en',
    }}>
    <title lang="en">Join Our Team | On The Block Real Estate International</title>
    <meta name="description" content="On The Block Real Estate International Is A 
    Technology Based Company, So Our Agents are Mobile, Effective & Not Burdened 
    With Paperwork. We Have Been Servicing California For More Than 20+ Years, And 
    As Of 2021 We Are Brokered By eXp Realty." lang="en"/>
    </Helmet>
      <section className="pt-20 md:pt-40 JoinBack">
        <div className="mx-auto px-4">
            <h1 className="text-6xl font-bold leading-none text-white pb-40 drop-shadow-sm">
               Be Part<br/>
               Of Our Team
            </h1>
        </div>
      </section>
  
      <section className="pt-5">
        <div className="container mx-auto">
        <h2 className="text-3xl mx-5 font-medium">Foundation For Success</h2>
        <div className="container mx-auto my-5 py-5 bg-gray-200 rounded-lg">
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
            • 80/20 Commission Splits with a Cap of $16,000.00 with our Partnership with eXp Realty. No Franchise Fee is ever charged.
            </p>
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
            • Training, Technology, Insurance, Worker’s Compensation, and Legal are all provided.
            </p>
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
            • Free Training by a Top Trainer in the Real Estate Industry – 1st Sale in 60 Days! Guaranteed!
            </p>
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
            • Sales/Marketing Materials to Assist Agents in Generating Sales Quickly
            </p>
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
            • Handbook with Scripts, Client Handling, Offer Guide, Listing Guide to Assist with Fast Startup
            </p>
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
            • Partnerships with Other Agents to Prospect and Generate Sales Together and Quickly
            </p>
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
            • Free Use of Open House Signs and Marketing Materials to Save You Money Up Front
            </p>
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
            • Business Cards, Polo Shirt and Access to Marketing Materials Provided FREE.
            </p>
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
            • Technology Based Broker so Agents are Mobile, Effective and Not Burdened with Paperwork/Offices
            </p>
            <p className="mt-8 mb-5 mx-5 text-xl font-light">
            • Open Your Own Office Quickly – Cheap, Easy and No Broker License Required – Ask for Details!
            </p>
            <a href="tel:5629721390">
              <Button className="mt-8 mb-5 mx-5 text-xl" size="l">
              Call Now
              </Button>
            </a>
        </div>
          <img src={Exp} className="w-64 mt-10 px-10 sm:-mx-3"/>
        </div>
      </section>
    </Layout>
  );

export default JoinUs;